<template>
    <div v-if="displayTrialing" class="v-application bg-primary rounded-lg p-8">
        <v-row>
            <v-col cols="4" class="d-flex align-center pt-4">
                <v-icon
                    large
                    color="yellow"
                    class="mr-4"
                >
                    mdi-alert
                </v-icon>
                <div class="pt-3">
                    <span v-html="formatMaxRequestsString()" class="white--text"></span>
                </div>
            </v-col>
            <v-col cols="4">
                <div class="pt-4">
                    <h4 v-html="$t('component_openPurchase_offer')" class="text-center white--text font-weight-bold"></h4>
                </div>
            </v-col>
            <v-col cols="4">
                <div class="pt-4">
                    <v-btn
                        @click="openPurchase"
                        class="bg-white primary--text font-weight-bold text-h6 rounded p-6"
                    >
                        {{ $t('component_openPurchase_cta') }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: "OpenPurchaseCta",
    data() {
        return {
            displayTrialing: false,
        }
    },
    computed: {
        ...mapGetters({
            activeSubscription:"customersManager/getActiveSubscription",
            maxRequestCount: "customersManager/getMaxRequestCount",
        }),
        purchasePlanUrl(){
            return new URL(`${process.env.VUE_APP_CUSTOMERS_MANAGER_FRONTEND_BASE_URI}/purchase/select-plan?subscription-id=${this.activeSubscription().id}`).href
        },
    },
    methods: {
        openPurchase(){
            window.open(this.purchasePlanUrl, '_blank')
        },
        formatMaxRequestsString() {
            return this.$t('component_openPurchase_keywordsLimit').replace('[*KEYWORDS-LIMIT*]', this.maxRequestCount())
        },
    },
    mounted() {
        if (this.activeSubscription().state === 'trialing') {
            this.displayTrialing = true
        }
    }
}
</script>

<style scoped>

</style>
