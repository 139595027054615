<template>
    <component-container
        :card-title="$t('component_marketSegmentVisibility_title')"
        :height="495"

        :component-data="{
            response:marketSegmentVisibility,
            hasData:marketSegmentVisibility.data
        }"

        :component-config="{
            hasConfig:keywordGroupsConfiguration.length,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute:displayPlaceholderConfigButton ? 'segments-configuration' : null,
            placeholderTitle:$t('component_componentContainer_noMarketSegment_title'),
            placeholderText:$t('component_componentContainer_noMarketSegment_text')
        }"

        @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
    >

        <template v-slot:content>
            <div class="v-application pt-4">
                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:marketSegmentVisibility.data}"
                    :agGridStyle="`height:${tableHeightByRowCount(marketSegmentVisibility.data)}px;`"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                    :show-pagination="false"
                    ref="agGridTable"
                />
                <div v-if="displayLinkButton">
                    <v-btn
                        outlined
                        :to="{name:'segments_configuration'}"
                        color="primary"
                        style="width: calc(100% - 35px); position: absolute; bottom: 18px"
                    >
                        {{ $t('component_marketSegmentVisibility_cta') }}<v-icon right>mdi-chevron-double-right</v-icon>
                    </v-btn>
                </div>
            </div>
        </template>

    </component-container>
</template>

<script>
import apiCalls from "@/api/axios/dataReport/dashboardRecommendations";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";
import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'

export default {
    name: "MarketSegmentVisibility",
    data() {
        return {
            marketSegmentVisibility: null,
            cardContainerFullscreen:true,
            columnDefs: [
                {headerName: this.$t('component_marketSegmentVisibility_tableHeader_segment'), field: 'keywordGroupName', cellRendererFramework: 'CellRendererTextWithIcon', cellClass:'ag-grid-cell-left', headerClass:'ag-grid-header-left'},
                {headerName: this.$t('component_marketSegmentVisibility_tableHeader_estimatedTraffic'), field: 'cumulatedVisibility', width: 120, cellRendererFramework: 'CellRendererFormatedNumber', cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellTraffic},
                {headerName: this.$t('component_marketSegmentVisibility_tableHeader_keywordsCount'), field: 'keywordCount', width: 120, cellClass:'ag-grid-cell-right', cellRendererFramework: 'CellRendererFormatedNumber', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellGeneric},
                {headerName: this.$t('component_marketSegmentVisibility_tableHeader_searchesCount'), field: 'cumulatedSearchCount',  width: 120, cellRendererFramework: 'CellRendererFormatedNumber', cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellGeneric},
            ]
        }
    },
    components: {
        AgGridTable
    },
    props: {
        displayLinkButton:{type: Boolean, default: false},
        displayPlaceholderConfigButton:{type: Boolean, default: true},
        hideOnNoConfig:{type: Boolean, default: false}
    },
    watch:{
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods: {
        formatData(jsonData) {
            return jsonData.content.map((data)=>{
                return {
                    keywordGroupName: data.keywordGroupName,
                    cumulatedVisibility:data.cumulatedVisibility,
                    keywordCount:data.keywordCount,
                    cumulatedSearchCount: data.cumulatedSearchCount,
                    iconName: 'tag',
                    iconColor: 'purple lighten-3',
                    iconSize: 'small'
                }
            })
        },
        initComponentData(){
            this.getComponentData({
                componentName:'marketSegmentVisibility',
                promise:(!this.projectCreationWaiting && this.$router.currentRoute.params.projectId && this.keywordGroupsConfiguration.length >0) ? apiCalls.readMarketSegmentVisibility(this.$router.currentRoute.params.projectId, {params: {
                        'date-range-type':this.globalFilterFrequency,
                        'search-geolocation-id':this.globalFilterLocation
                    }}) : false,
                dataFormatter: this.formatData
            })
        },
    },
    beforeMount() {
        this.initComponentData()
    }
}
</script>

<style scoped>

</style>
