var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_marketSegmentVisibility_title'),"height":495,"component-data":{
        response:_vm.marketSegmentVisibility,
        hasData:_vm.marketSegmentVisibility.data
    },"component-config":{
        hasConfig:_vm.keywordGroupsConfiguration.length,
        hideOnNoConfig:_vm.hideOnNoConfig,
        redirectRoute:_vm.displayPlaceholderConfigButton ? 'segments-configuration' : null,
        placeholderTitle:_vm.$t('component_componentContainer_noMarketSegment_title'),
        placeholderText:_vm.$t('component_componentContainer_noMarketSegment_text')
    }},on:{"setTableMenuFullscreen":function($event){_vm.cardContainerFullscreen = !_vm.cardContainerFullscreen}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"v-application pt-4"},[_c('ag-grid-table',{ref:"agGridTable",attrs:{"show-floating-filter":false,"table-data":{columnDefs:_vm.columnDefs,rowData:_vm.marketSegmentVisibility.data},"agGridStyle":`height:${_vm.tableHeightByRowCount(_vm.marketSegmentVisibility.data)}px;`,"is-card-container-fullscreen":_vm.cardContainerFullscreen,"show-pagination":false}}),(_vm.displayLinkButton)?_c('div',[_c('v-btn',{staticStyle:{"width":"calc(100% - 35px)","position":"absolute","bottom":"18px"},attrs:{"outlined":"","to":{name:'segments_configuration'},"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('component_marketSegmentVisibility_cta'))),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-double-right")])],1)],1):_vm._e()],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }