<template>
    <page-placeholder v-if="project.isLoaded">

        <v-row>
            <v-col :xl="6" :lg="6" :cols="12">
                <global-google-visibility/>
            </v-col>
            <v-col :xl="6" :lg="6" :cols="12">
                <action-priorities :display-link-button="true"/>
            </v-col>
        </v-row>

        <v-row class="v-application" v-if="displayHelpMessage && !isNoiise">
            <v-col :cols="1">
                <div class="primary" style="margin-left:auto;width: 40px; height: 40px; padding-top: 8px; border-radius: 100%; text-align: center">
                    <v-icon color="white" >mdi-face-man</v-icon>
                </div>
            </v-col>
            <v-col :cols="10">
                <span>{{ $t('common_master_yooda') }}</span>
                <v-card color="#eff8ff">
                    <v-card-text class="py-4 px-6 relative">
                        <v-icon style="position: absolute; right: 0; top: 0" @click="closeHelper">mdi-close</v-icon>
                        <p style="font-size: 14px; line-height: 22px; margin-bottom: 0" v-html="$t('view_dashboardRecommendations_helpMessage_keywords')"/>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col :cols="1">

            </v-col>
        </v-row>

        <v-row>
            <v-col :xl="6" :lg="6" :cols="12">
                <most-visible-competitors :display-link-button="true"/>
            </v-col>
            <v-col :xl="6" :lg="6" :cols="12">
                <market-segment-visibility :display-link-button="true"/>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <open-purchase-cta/>
            </v-col>
        </v-row>

        <v-row>
            <v-col :xl="4" :lg="4" :md="4" :cols="12">
                <traffic-quality-total-traffic/>
            </v-col>
            <v-col :xl="4" :lg="4" :md="4" :cols="12">
                <traffic-quality-total-sale/>
            </v-col>
            <v-col :xl="4" :lg="4" :md="4" :cols="12">
                <traffic-quality-total-turnover/>
            </v-col>
        </v-row>

        <v-row>
            <v-col :xl="6" :lg="6" :cols="12">
                <top-best-pages :display-link-button="true"></top-best-pages>
            </v-col>
            <v-col :xl="6" :lg="6" :cols="12">
                <top-best-keywords :display-link-button="true"></top-best-keywords>
            </v-col>
        </v-row>
    </page-placeholder>
</template>

<script>
import GlobalGoogleVisibility from "@/components/yooda-components/app/dashboardRecommendations/GlobalGoogleVisibility";
import PagePlaceholder from "@/components/yooda-components/PagePlaceholder";
import ActionPriorities from "@/components/yooda-components/app/dashboardRecommendations/ActionPriorities";
import MostVisibleCompetitors from "@/components/yooda-components/app/dashboardRecommendations/MostVisibleCompetitors";
import MarketSegmentVisibility from "@/components/yooda-components/app/dashboardRecommendations/MarketSegmentVisibility";
import TopBestPages from "@/components/yooda-components/app/dashboardRecommendations/TopBestPages";
import TopBestKeywords from "@/components/yooda-components/app/dashboardRecommendations/TopBestKeywords";
import OpenPurchaseCta from "@/components/yooda-components/app/dashboardRecommendations/OpenPurchaseCta";
import TrafficQualityTotalTraffic from "@/components/yooda-components/app/traffic/TrafficQualityTotalTraffic.vue";
import TrafficQualityTotalSale from "@/components/yooda-components/app/traffic/TrafficQualityTotalSale.vue";
import TrafficQualityTotalTurnover from "@/components/yooda-components/app/traffic/TrafficQualityTotalTurnover.vue";

export default {
    name: "DashboardRecommendations",
    data(){
        return{
            displayHelpMessage:false
        }
    },
    computed:{

        isNoiise(){
            return  process.env.VUE_APP_PRODUCT_FAMILY_HANDLE === 'noiise'
        },
    },
    methods:{
        closeHelper(){
            localStorage.setItem('dashboardRecommendationShowHelp','true')
            this.displayHelpMessage = false
        }
    },
    components: {
        MostVisibleCompetitors,
        MarketSegmentVisibility,
        ActionPriorities,
        TopBestPages,
        TopBestKeywords,
        GlobalGoogleVisibility,
        PagePlaceholder,
        OpenPurchaseCta,
        TrafficQualityTotalTraffic,
        TrafficQualityTotalSale,
        TrafficQualityTotalTurnover,
    },
    beforeMount(){
        if(!localStorage.getItem('dashboardRecommendationShowHelp')){
            this.displayHelpMessage = true
            if(localStorage.getItem('settingKeywordDone') && localStorage.getItem('settingMarketDone')){
                localStorage.setItem('dashboardRecommendationShowHelp','true')
            }
        }

        localStorage.setItem('defaultDashboardName','dashboard_recommendations')
        this.setDefaultDashboardName('dashboard_recommendations')
    }
}
</script>

<style scoped>

</style>
